import { CSS_FILES, FONTS } from "~/data/config";
import { Mode } from "~/types";

export function classNames(
  ...classNames: (string | null | undefined)[]
): string {
  return classNames.filter((value) => value).join(" ");
}

export function getHexColorBrightness(color: string) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  return (r * 299 + g * 587 + b * 114) / 1000;
}

export const getCSSFile = (clientId: keyof typeof CSS_FILES): string => {
  return CSS_FILES[clientId];
};

export type CSSFilesWithoutDefault = Omit<typeof CSS_FILES, "default">;

export const getFonts = (
  mode?: Mode,
  clientId?: keyof CSSFilesWithoutDefault
): string[] => {
  if (mode === "rnr") {
    return [...FONTS.rnr];
  } else if (mode === "sdk" && clientId) {
    const sdkFont = FONTS.sdk.find((fontObj) => fontObj[clientId]);
    if (sdkFont) {
      return [sdkFont[clientId]];
    }
  }
  return FONTS.default;
};
